import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});
  const [descricao, setDescricao] = useState('');
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [custo, setCusto] = useState('');
  const [tipo, setTipo] = useState('');
  const [foto, setFoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [status, setStatus] = useState('Procurar...');

  const alterarFoto = async (e) => {
    if (e.target.files.length > 0) {
      setStatus(`${e.target.files[0].name}`);
      setFoto(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      setStatus('Procurar...');
      setPreview(null);
    }
  };

  const atualizar = async (e) => {
    e.preventDefault();
    
    if (!nome) {
      toast.error('Informe o nome');
      return;
    }

    if (!codigo) {
      toast.error('Informe o código');
      return;
    }

    if (!descricao) {
      toast.error('Informe a descrição');
      return;
    }

    if (!custo) {
      toast.error('Informe o custo');
      return;
    }

    if (!tipo) {
      toast.error('Informe o tipo');
      return;
    }

    setEditando(true);

    let formData = new FormData();
    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('codigo', codigo);
    formData.append('custo', Number(custo) / 100);
    formData.append('tipo', tipo);
    
    if (foto) {
      formData.append('foto', foto);
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    try {
      const resultado = await api.put(`/api/premios/${data.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (resultado) {
        toast.success('Prêmio atualizado com sucesso', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        props.onHide();
        props.atualizarPremio({
          ...{ 
            nome, 
            descricao, 
            codigo, 
            custo: Number(custo) / 100, 
            tipo 
          },
          id: data.id,
        });
      }
    } catch (error) {
      console.error('Erro ao atualizar:', error);
      toast.error('Erro ao atualizar prêmio', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    } finally {
      setEditando(false);
    }
  };

  useEffect(() => {
    if (props.data) {
      if (!props.data.tipo) {
        props.data.tipo = 'geral';
      }
      setData(props.data);
      setNome(props.data.nome);
      setDescricao(props.data.descricao);
      setCodigo(props.data.codigo);
      setCusto(Number(props.data.custo) * 100);
      setTipo(props.data.tipo);
      setPreview(props.data.diretorioFoto);
      setStatus('Procurar...');
    }
  }, [props]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <Form className="form" onSubmit={atualizar}>
            <label htmlFor="foto">Foto</label>
            <input
              type="file"
              id="foto"
              name="foto"
              style={{ display: 'none' }}
              onChange={alterarFoto}
            />
            <input
              className="text-left"
              id="botao"
              type="button"
              value={status}
              onClick={(e) => {
                document.getElementById('foto').click();
              }}
            />
            {preview && (
              <img alt="Preview" src={preview} className="mt-3 mb-3 w-100" />
            )}
            <label htmlFor="codigo">Código</label>
            <Input
              id="codigo"
              name="codigo"
              type="text"
              placeholder="Código"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            />
            <label htmlFor="nome">Nome</label>
            <Input
              id="nome"
              name="nome"
              type="text"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <label htmlFor="descricao">Descrição</label>
            <textarea
              id="descricao"
              name="descricao"
              placeholder="Descrição"
              className="premio-desc p-2"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
            <label htmlFor="custo">Valor em Pontos</label>
            <Input
              id="custo"
              name="custo"
              type="number"
              step="0.01"
              min="0"
              placeholder="Exemplo: 150 para 150 Pontos"
              value={custo}
              onChange={(e) => setCusto(e.target.value)}
            />
            <small className="text-muted">
              Digite o valor em pontos. Exemplo: 150 para 150 Pontos
            </small>

            <label htmlFor="tipo" className="mt-3">Tipo</label>
            <select
              id="tipo"
              name="tipo"
              placeholder="Tipo"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <option disabled value="">
                Selecione o tipo
              </option>
              <option value="geral">Geral</option>
              <option value="bivolt">Bivolt</option>
              <option value="credito">Crédito</option>
              <option value="recarga">Recarga</option>
            </select>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
